import React from "react"
import { injectIntl, Link } from "gatsby-plugin-intl"
import { graphql } from 'gatsby';

import Layout from "../components/layout/layout"
import SEO from "../components/seo"
import Banner from "../components/banner";
import Section from "../components/section";
import Image from "../components/image";
import AdvTitle from '../components/advTitle';
import FunctionalityShowcase from "../components/functionality-showcase";
import ImageColumns from "../components/image-columns";
import ImagesWithTextContainer from '../components/images-with-text-container';

export const setup = graphql`
    query ProductsQuery
    {
        allSetupsJson {
            edges
            {
                node
                {
                    colSize
                    className
                    imageName
                    imageAlt
                    title
                }
            }
        },
        allHelpItemsJson {
            edges {
                node {
                    colSize
                    className
                    imageName
                    imageAlt
                    title
                    description
                }
            }
        }
    }
`; 

const Products = ({ intl, data }) => {
    return <Layout>
        <SEO title={intl.formatMessage({id: "Our Products"})} description={intl.formatMessage({id: "Business up and more"})} />
        
        <Banner 
            className="banner" 
            filename="products-banner.png" 
            title={intl.formatMessage({id: "Our technologies"})+", "+intl.formatMessage({id: "with your brand and image"})} 
            subtitle={intl.formatMessage({id: "Use our tools to manage your entrepreneurship and investment ecosystem"})}
        />

        <Section>
            <div className="row align-items-center page-introduction">
                <div className="col-md-6 introduction-left products-left">
                <p>{intl.formatMessage({id: "Fast, flexible and fully customizable"})}</p>
                <h3>
                    {intl.formatMessage({id: "Connect with your network and finance your business"})}
                </h3>
                </div>
                <div className="col-md-6 introduction-right products-right">
                <p>{intl.formatMessage({id: "Our products have been built from our years of experience in the world of entrepreneurship and investment. Each of them can help you in the different value propositions you want to offer to your own clients, from business development to the financing of these, using different digital channels. Contract our products here or contact us to schedule a meeting"})}:</p>
                <ul>
                    <li>{intl.formatMessage({id: "Business Up Platform"})}</li>
                    <li>{intl.formatMessage({id: "Crowdfunding Platform"})}</li>
                    <li>{intl.formatMessage({id: "Goldsmith® Toolkit"})}</li>
                </ul>
                </div>
            </div>
        </Section>

        <Section title={intl.formatMessage({id: "Build your own online entrepreneurship platform"})}>
            <div className="container business-up">
                <div className="row">
                    <div className="col-md-6 align-self-center">
                        <Image filename="products-business-up-laptop.png" />
                        <div className="free-trial-seal">
                            <div className="free-trial-text">
                                {intl.formatMessage({id: "Try it"})}
                                <span>{intl.formatMessage({id: "Free"})}</span>
                                {intl.formatMessage({id: "for"})}
                            </div>
                            <div className="free-trial-period">
                                <span>7</span>
                                {intl.formatMessage({id: "days"})}
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6 business-up-features">
                        <AdvTitle title={intl.formatMessage({id: "Business Up"})} />
                        <ul>
                            <li>{intl.formatMessage({id: "Own web"})}</li>
                            <li>{intl.formatMessage({id: "Usage of your own domain"})}</li>
                            <li>{intl.formatMessage({id: "Brand Look&Feel"})}</li>
                            <li>{intl.formatMessage({id: "Editable pages"})}</li>
                            <li>{intl.formatMessage({id: "Reception and filtering projects"})}</li>
                            <li>{intl.formatMessage({id: "Goldsmith® model analysis"})}</li>
                            <li>{intl.formatMessage({id: "Own diagnostic KPIs"})}</li>
                            <li>{intl.formatMessage({id: "Business document management"})}</li>
                            <li>{intl.formatMessage({id: "Online mentoring space"})}</li>
                            <li>{intl.formatMessage({id: "Business showcase"})}</li>
                            <li>{intl.formatMessage({id: "Events and community"})}</li>
                            <li>{intl.formatMessage({id: "100% autonomous administration"})}</li>
                            <li>{intl.formatMessage({id: "Administrator training and support"})}</li>
                            <li>{intl.formatMessage({id: "Available in Spanish, English and Portuguese"})}</li>
                        </ul>
                        <div className="form-trigger">
                            <p className="free-trial-trigger-text">{intl.formatMessage({id: "For 149€ per month"})}</p>
                            <a href="https://do.adventurees.com/user/create.html">{intl.formatMessage({id: "Let's begin"})}</a>
                        </div>
                    </div>
                </div>
            </div>
        </Section>

        <Section title={intl.formatMessage({id: "Get to know the platform's insides"})} className="features d-none d-xl-block">
            <FunctionalityShowcase />
        </Section>

        <Section title={intl.formatMessage({id: "Set up your platform in 10 minutes"})} className="setup-steps d-none d-lg-block">
            <ImageColumns columns={data.allSetupsJson.edges} className="purchase-steps" />
            <div className="form-trigger">
                <p className="free-trial-trigger-text">{intl.formatMessage({id: "Start now your FREE TRIAL for 7 days"})}</p>
                <a href="https://do.adventurees.com/user/create.html">{intl.formatMessage({id: "Start now"})}</a>
            </div>
        </Section>

        <Section title={intl.formatMessage({id: "Do you need additional help to build your platform?"})} className="support-items">
            <ImagesWithTextContainer elements={data.allHelpItemsJson.edges} className="help-items" />
        </Section>

        <Section title={intl.formatMessage({id: "Any questions?"})}>
            <div className="container contact-us-footer">
                <div className="contact-image">
                    <Image filename={"call_icon.png"} alt="Call us" />
                </div>
                <p>{intl.formatMessage({id: "Contact us at"})} <a href="tel:+34 91 193 00 61">+34 91 193 00 61</a></p>
                <p>{intl.formatMessage({id: "Or you can send us an email to"})} <a href="mailto://info@adventurees.com">info@adventurees.com</a></p>
            </div>
        </Section>
    </Layout>
}

export default injectIntl(Products);
