import React from "react";
import { injectIntl } from 'gatsby-plugin-intl';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronRight } from '@fortawesome/free-solid-svg-icons'
import Image from "../components/image";

import "../styles/features.less";

class FunctionalityShowcase extends React.Component
{
    componentDidMount() 
    {
        this.changeFunctionality('design');
    }

    changeFunctionality(chosen_section)
    {
        // This element and section
        var clicked_button = document.querySelector('[data-section="'+chosen_section+'"]');

        // Hide all elements
        document.querySelectorAll('.window .app-window .gatsby-image-wrapper').forEach(e => e.style.display = 'none');
        document.querySelectorAll('.window .feature-explanation > div').forEach(e => e.style.display = 'none');
        
        // Remove active classes
        document.querySelectorAll('.navigation-menu li').forEach(e => e.classList.remove("active"));

        // Adding the new active class
        clicked_button.parentNode.classList.add("active");
        
        // Showing the new element
        Array.from(document.getElementsByClassName(chosen_section)).forEach(e => e.style.display = 'block');
    }

    render()
    {
        return <div>
                <div className="window">
                    <Image filename="browser-header.png" className="browser-header" />
                    <div className="desktop">
                        <div className="navigation-menu">
                            <ul>
                                <li><FontAwesomeIcon icon={faChevronRight} /><button data-plan="business-up" data-section="design" onClick={() => this.changeFunctionality('design')}>{this.props.intl.formatMessage({id: "Design"})}</button></li>
                                <li><FontAwesomeIcon icon={faChevronRight} /><button data-plan="business-up" data-section="view-projects" onClick={() => this.changeFunctionality('view-projects')}>{this.props.intl.formatMessage({id: "Projects"})}</button></li>
                                <li><FontAwesomeIcon icon={faChevronRight} /><button data-plan="business-up" data-section="community" onClick={() => this.changeFunctionality('community')}>{this.props.intl.formatMessage({id: "Community"})}</button></li>
                                <li><FontAwesomeIcon icon={faChevronRight} /><button data-plan="business-up" data-section="calendar" onClick={() => this.changeFunctionality('calendar')}>{this.props.intl.formatMessage({id: "Calendar"})}</button></li>
                                <li><FontAwesomeIcon icon={faChevronRight} /><button data-plan="business-up" data-section="resources" onClick={() => this.changeFunctionality('resources')}>{this.props.intl.formatMessage({id: "Library"})}</button></li>
                                <li className="separator"></li>
                                <li className="features-section-title"><h5>{this.props.intl.formatMessage({id: "Administrator"})}</h5></li>
                                <li><FontAwesomeIcon icon={faChevronRight} /><button data-plan="business-up" data-section="project-call" onClick={() => this.changeFunctionality('project-call')}>{this.props.intl.formatMessage({id: "Calls"})}</button></li>
                                <li><FontAwesomeIcon icon={faChevronRight} /><button data-plan="business-up" data-section="projects" onClick={() => this.changeFunctionality('projects')}>{this.props.intl.formatMessage({id: "Projects"})}</button></li>
                                <li><FontAwesomeIcon icon={faChevronRight} /><button data-plan="business-up" data-section="users" onClick={() => this.changeFunctionality('users')}>{this.props.intl.formatMessage({id: "Users"})}</button></li>
                                <li><FontAwesomeIcon icon={faChevronRight} /><button data-plan="business-up" data-section="analysis" onClick={() => this.changeFunctionality('analysis')}>{this.props.intl.formatMessage({id: "Analysis"})}</button></li>
                                <li><FontAwesomeIcon icon={faChevronRight} /><button data-plan="business-up" data-section="website" onClick={() => this.changeFunctionality('website')}>{this.props.intl.formatMessage({id: "Website"})}</button></li>
                            </ul>
                        </div>
                        <div className="app-window">
                            <Image filename="features_design.png" className="design" alt="Layout screenshot" />
                            <Image filename="features_view_projects.png" className="view-projects" />
                            <Image filename="features_community.png" className="community" />
                            <Image filename="features_calendar.png" className="calendar" />
                            <Image filename="features_resources.png" className="resources" />

                            <Image filename="features_admin_project-calls.png" className="project-call" />
                            <Image filename="features_admin_projects.png" className="projects" />
                            <Image filename="features_admin_users.png" className="users" />
                            <Image filename="features_admin_analysis.png" className="analysis" />
                            <Image filename="features_admin_website.png" className="website" />
                        </div>
                        <div className="feature-explanation">
                            <div className="design">
                                <div className="feature-image">
                                    <Image filename="features_admin_website_icon.png" />
                                </div>
                                <div className="feature-text">
                                    <p className="feature-name">{this.props.intl.formatMessage({id: "Design"})}</p>
                                    <p className="feature-description">
                                        {this.props.intl.formatMessage({id: "Set your platform with your own brand and design. Decide the content you want to display in each page and make the updates you need without the need of a tech team."})}
                                    </p>
                                </div>
                            </div>
                            <div className="view-projects">
                                <div className="feature-image">
                                    <Image filename="features_admin_projects_icon.png" />
                                </div>
                                <div className="feature-text">
                                    <p className="feature-name">{this.props.intl.formatMessage({id: "Projects"})}</p>
                                    <p className="feature-description">
                                        {this.props.intl.formatMessage({id: "Displays showcase projects and allows different search filters. Each project will have it's own card where all its information and documents will be displayed."})}
                                    </p>
                                </div>
                            </div>
                            <div className="community">
                                <div className="feature-image">
                                    <Image filename="features_admin_users_icon.png" />
                                </div>
                                <div className="feature-text">
                                    <p className="feature-name">{this.props.intl.formatMessage({id: "Community"})}</p>
                                    <p className="feature-description">
                                        {this.props.intl.formatMessage({id: "Create your own users community with different profiles. Manage their visibility and filters. Each user will have their own page in which they can share information about themselves and how to help your community."})}
                                    </p>
                                </div>
                            </div>
                            <div className="calendar">
                                <div className="feature-image">
                                    <Image filename="features_admin_website_icon.png" />
                                </div>
                                <div className="feature-text">
                                    <p className="feature-name">{this.props.intl.formatMessage({id: "Calendar"})}</p>
                                    <p className="feature-description">
                                        {this.props.intl.formatMessage({id: "Manage your event's calendar. Show all the activities your organization dows and keep a constant communication with your users. You'll also be able to create recurring events in case they recur in time and differentiate the events by colors in the calendar."})}
                                    </p>
                                </div>
                            </div>
                            <div className="resources">
                                <div className="feature-image">
                                    <Image filename="features_admin_library_icon.png" />
                                </div>
                                <div className="feature-text">
                                    <p className="feature-name">{this.props.intl.formatMessage({id: "Library"})}</p>
                                    <p className="feature-description">
                                        {this.props.intl.formatMessage({id: "Create different categories/channels and upload all kind of documents and resources. It allows to upload PDFs, videos from YouTube or Vimeo, texts, images, slideshows, etc... Organize all the documentation you want to share with your community from here."})}
                                    </p>
                                </div>
                            </div>
                            

                            <div className="project-call">
                                <div className="feature-image">
                                    <Image filename="features_admin_project-calls_icon.png" />
                                </div>
                                <div className="feature-text">
                                    <p className="feature-name">{this.props.intl.formatMessage({id: "Calls"})}</p>
                                    <p className="feature-description">
                                        {this.props.intl.formatMessage({id: "Create and manage calls to attract projects and businesses. Filter and sort projects by call. Create as many as you need. Receive digitally every project and business call. Analyze each one and assign them to evaluators if you need to. Choose the best to work with them."})}
                                    </p>
                                </div>
                            </div>
                            <div className="projects">
                                <div className="feature-image">
                                    <Image filename="features_admin_projects_icon.png" />
                                </div>
                                <div className="feature-text">
                                    <p className="feature-name">{this.props.intl.formatMessage({id: "Projects"})}</p>
                                    <p className="feature-description">
                                        {this.props.intl.formatMessage({id: "Work with each project, evaluate it, asign persons to the team, leave comments, work with mentors or project chiefs and digitally register all the work done with the businesses. Keep the documentary base of each project in one place. Decide the visibility of each business and user."})}
                                    </p>
                                </div>
                            </div>
                            <div className="users">
                                <div className="feature-image">
                                    <Image filename="features_admin_users_icon.png" />
                                </div>
                                <div className="feature-text">
                                    <p className="feature-name">{this.props.intl.formatMessage({id: "Users"})}</p>
                                    <p className="feature-description">
                                        {this.props.intl.formatMessage({id: "Keep all users of your platform under control. Decide the different profiles you need (entrepreneurs, mentors, investors, advisors, etc.). Asign projects to them, display them in the users community, etc."})}
                                    </p>
                                </div>
                            </div>
                            <div className="analysis">
                                <div className="feature-image">
                                    <Image filename="features_admin_analysis_icon.png" />
                                </div>
                                <div className="feature-text">
                                    <p className="feature-name">{this.props.intl.formatMessage({id: "Analysis"})}</p>
                                    <p className="feature-description">
                                        {this.props.intl.formatMessage({id: "Use our analysis tools or create your own. You can create your own questions and answers and manage the different steps of each question. Multiple choice of answers."})}
                                    </p>
                                </div>
                            </div>
                            <div className="website">
                                <div className="feature-image">
                                    <Image filename="features_admin_website_icon.png" />
                                </div>
                                <div className="feature-text">
                                    <p className="feature-name">{this.props.intl.formatMessage({id: "Website"})}</p>
                                    <p className="feature-description">
                                        {this.props.intl.formatMessage({id: "Our platform is the entrepreneurshipment's Wordpress. You can change all the pages and designs by yourself. Create new pages, modify the styles, work in basic or advanced mode and modify the menu to your liking. It also has an events calendar that you can manage to communicate all the activities to your community."})}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
        </div>;
    }
}


export default injectIntl(FunctionalityShowcase);
